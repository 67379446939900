import { supabase } from "./supabase";

async function getNews() {
  const { data, error } = await supabase.from("news").select();
  localStorage.setItem("news", JSON.stringify(data));
}

async function getDokumente() {
  const { data, error } = await supabase.from("dokumente").select();
  localStorage.setItem("dokumente", JSON.stringify(data));
}

getNews();
getDokumente();
